import React from 'react'

import BaseHistogram from './BaseHistogram'

const MarkupSpeedHistogram = ({ data }) => (
  <BaseHistogram
    title='Скорость разметки (батчей/час)'
    color='#F4A261'
    data={data}
    dataKey='speed'
    totalLabel='Средняя скорость разметки (батчей/час)'
    totalDataKey='avg_speed'
  />
)

export default MarkupSpeedHistogram
