import React from 'react'
import { Alert } from 'react-bootstrap'

export default () => (
  <div className='text-center'>
    <Alert variant='danger'>
      <Alert.Heading>Доступ запрещен</Alert.Heading>
      <hr />
      <div>Для доступа к данному ресурсу нужен аккаунт администратора</div>
    </Alert>
  </div>
)
