import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, updateUser } from '../../../../actions/adminPanel'

import TaskManager from './TaskManager'
import styles from './user-panel.module.css'

const initialState = {
  email: '',
  password: '',
  last_name: '',
  first_name: '',
  middle_name: ''
}

const labels = {
  email: 'Email',
  password: 'Новый пароль',
  last_name: 'Фамилия',
  first_name: 'Имя',
  middle_name: 'Отчество'
}

export default function UserForm ({ user, currentUser }) {
  const [formData, setFormData] = useState(Object.assign({}, initialState))

  const [showModal, setShowModal] = useState(false)

  const isFetching = useSelector(
    state => state.adminPanelReducer.isFetching
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setFormData(Object.assign({}, initialState))
  }, [user])

  let fieldsEmpty = true
  for (const [key] of Object.entries(formData)) {
    if (formData[key] !== '') {
      fieldsEmpty = false
      break
    }
  }
  const canSave = !fieldsEmpty

  const canDelete = user.id !== Number(currentUser.id)

  const saveData = async () => {
    const data = {}
    for (const [key, value] of Object.entries(formData)) {
      if (formData[key] !== '') {
        data[key] = value
      }
    }
    dispatch(updateUser(user, data))
  }

  const removeUser = async () => {
    dispatch(deleteUser(user))
    setShowModal(false)
  }

  const updateFormField = (key, e) => {
    const newFormData = { ...formData }
    newFormData[key] = e.target.value
    setFormData(newFormData)
  }

  return (
    <Col className='mt-2 col-8 offset-2'>
      <Form>
        {Object.entries(formData).map((key, value) => {
          key = key[0]
          return (
            <Form.Group key={key}>
              <Form.Label>{labels[key]}</Form.Label>
              <Form.Control
                value={formData[key]}
                onChange={(e) => updateFormField(key, e)}
                placeholder={user[key]}
              />
            </Form.Group>
          )
        })}
        <Container className='mt-1 mb-1'>
          <Row>
            <Button
              className='mr-auto'
              variant='success'
              disabled={!canSave || isFetching}
              onClick={saveData}
            >
              Сохранить
            </Button>
            <Button
              variant='danger'
              disabled={!canDelete || isFetching}
              onClick={() => setShowModal(true)}
            >
              Удалить
            </Button>
          </Row>
        </Container>
      </Form>
      <Container className={styles['task-manager']}>
        {user.is_admin ? null : <TaskManager user={user} />}
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>Пользователь будет удален. Вы уверены?</Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isFetching}
            type='submit'
            variant='danger'
            onClick={removeUser}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}
