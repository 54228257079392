import React from 'react'
import { ListGroup, Pagination } from 'react-bootstrap'

const PaginatedList = ({
  items,
  page,
  totalPages,
  renderCallback,
  handlePageChange
}) => {
  if (totalPages === 0) {
    return null
  }

  return (
    <div>
      <ListGroup>{
        items.map(
          i => renderCallback(i)
        )
      }
      </ListGroup>
      <Pagination className='justify-content-center mt-3'>
        <Pagination.Prev
          disabled={page === 0}
          onClick={() => handlePageChange(page - 1)}
        />
        <Pagination.Item disabled>
          {`${page + 1}/${totalPages}`}
        </Pagination.Item>
        <Pagination.Next
          disabled={page === totalPages - 1}
          onClick={() => handlePageChange(page + 1)}
        />
      </Pagination>
    </div>
  )
}

export default PaginatedList
