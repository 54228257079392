import bar from 'plotly.js/lib/bar'
import Plotly from 'plotly.js/lib/core'
import histogram from 'plotly.js/lib/histogram'
import createPlotlyComponent from 'react-plotly.js/factory'

Plotly.register([
  bar,
  histogram

])
const Plot = createPlotlyComponent(Plotly)

export default Plot
