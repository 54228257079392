import React from 'react'
import { Pagination } from 'react-bootstrap'
import DateSelector from './DateSelector/component'

const PaginatedDateSelector = ({ date, handleDateChange }) => {
  const handlePaginationClick = (dayBias) => {
    let day = date.getDate()
    day += dayBias
    const newDate = new Date(date)
    newDate.setDate(day)
    handleDateChange(newDate)
  }

  return (
    <div>
      <Pagination width='100%'>
        <Pagination.Prev
          onClick={() => handlePaginationClick(-1)}
        />
        <DateSelector
          date={date}
          handleDateChange={handleDateChange}
        />
        <Pagination.Next
          onClick={() => handlePaginationClick(1)}
        />
      </Pagination>
    </div>
  )
}

export default PaginatedDateSelector
