import axios from 'axios'
import { push } from 'connected-react-router'

import { API_PREFIX } from './config'
import store from './store'
import { buildUrl } from './utils'

const errorScopeManager = async (f, expectedCodes = []) => {
  try {
    const res = await f()
    return res
  } catch (e) {
    if (typeof e.response !== 'undefined') {
      const status = e.response.data.error_code
      if (expectedCodes.includes(status)) {
        return {
          errorCode: e.response.status,
          msg: e.response.data.error_code
        }
      }
    }
    store.dispatch(push('/network_error'))
    return {}
  }
}

const login = (
  email,
  password,
  expectedCodes = []
) => errorScopeManager(async () => {
  const res = await axios.post(
    `${API_PREFIX}/login`,
    {
      email: email,
      password: password
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return res.data.data
}, expectedCodes)

const getUserById = (
  userId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.get(
    `${API_PREFIX}/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const getCurrentUser = () => {
  const userId = localStorage.getItem('user_id')
  return getUserById(userId)
}

const getTasks = (
  userId = null
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  let url = `${API_PREFIX}/tasks`
  if (userId !== null) {
    url += `?user_id=${userId}`
  }
  const res = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const getMarkup = (
  taskId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.get(
    `${API_PREFIX}/tasks/${taskId}/markup`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data
})

const getTask = (
  taskId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.get(
    `${API_PREFIX}/tasks/${taskId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const createTask = (
  data,
  expectedCodes = []
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.post(
    `${API_PREFIX}/tasks`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
}, expectedCodes)

const updateTask = (
  taskId,
  data
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.patch(
    `${API_PREFIX}/tasks/${taskId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const deleteTask = (
  taskId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  await axios.delete(
    `${API_PREFIX}/tasks/${taskId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return {}
})

const assignUser = (
  taskId,
  userId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.post(
    `${API_PREFIX}/tasks/${taskId}/assign_user`,
    {
      user_id: userId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const dissociateUser = (
  taskId,
  userId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.post(
    `${API_PREFIX}/tasks/${taskId}/dissociate_user`,
    {
      user_id: userId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const getUsers = (
  taskId = null
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  let url = `${API_PREFIX}/users`
  if (taskId !== null) {
    url += `?task_id=${taskId}`
  }
  const res = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const createUser = (
  data,
  expectedCodes = []
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const resp = await axios.post(
    `${API_PREFIX}/users`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return resp.data.data
}, expectedCodes)

const updateUser = (
  userId,
  data
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const resp = await axios.patch(
    `${API_PREFIX}/users/${userId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return resp.data.data
})

const deleteUser = (
  userId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  await axios.delete(
    `${API_PREFIX}/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return {}
})

const getBatch = (
  batchId,
  expectedCodes = []
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.get(
    `${API_PREFIX}/batches/${batchId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
}, expectedCodes)

const updateBatch = (
  batchId,
  state
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  await axios.patch(
    `${API_PREFIX}/batches/${batchId}`,
    {
      state: state
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  )
  return {}
})

const getBatches = (
  query,
  expectedCodes = []
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const url = buildUrl(
    `${API_PREFIX}/batches`,
    {
      ...query,
      per_page: 10
    }
  )
  const res = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data
}, expectedCodes)

const getBatchToMarkup = (
  taskId,
  expectedCodes = []
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.get(
    `${API_PREFIX}/tasks/${taskId}/batch_to_markup`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
}, expectedCodes)

const getTaskStatus = (
  taskId
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const res = await axios.get(
    `${API_PREFIX}/tasks/${taskId}/status`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const getImages = (
  batchId = null
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  let url = `${API_PREFIX}/images?per_page=1000`
  if (batchId !== null) {
    url += `&batch_id=${batchId}`
  }
  const res = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
})

const updateImage = (
  imageId,
  markup
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  await axios.patch(
    `${API_PREFIX}/images/${imageId}`,
    {
      markup: markup
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  )
  return {}
})

const loadImage = (
  imageId
) => {
  return new Promise(resolve => {
    const image = new Image()
    image.onload = () => {
      resolve(image)
    }
    image.src = `${API_PREFIX}/images/${imageId}/image`
  })
}

const getUserTimetracking = (
  userId,
  query,
  expectedCodes = []
) => errorScopeManager(async () => {
  const token = localStorage.getItem('access_token')
  const url = buildUrl(
    `${API_PREFIX}/users/${userId}/timetracking`,
    {
      ...query
    }
  )
  const res = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data.data
}, expectedCodes)

export default {
  login,
  getUserById,
  getCurrentUser,
  getTasks,
  getMarkup,
  getTask,
  createTask,
  updateTask,
  deleteTask,
  assignUser,
  dissociateUser,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getBatch,
  updateBatch,
  getBatches,
  getBatchToMarkup,
  getTaskStatus,
  getImages,
  updateImage,
  loadImage,
  getUserTimetracking
}
