/* NOTE: no refetching data on deletes and e.t.c.
*  used to prevent useless fetches and client-side data aggregation
*     - assignUser
*     - dissUser
*
*  If there are more than one admin, it may cause invalid info in admin panel!!!
* */

import { batch } from 'react-redux'
import { createAction } from 'redux-actions'
import MarkupApi from '../markupApi'
import { filterNullValues, toUnixTime } from '../utils'

export const lockFetch = createAction('LOCK_ADMIN_FETCH')

export const unlockFetch = createAction('UNLOCK_ADMIN_FETCH')

const itemCrudFactory = (crudApiMethods, itemKey, itemsKey) => {
  const [
    createItemMethod,
    readItemsMethod,
    updateItemMethod,
    deleteItemMethod
  ] = crudApiMethods

  const fetchItems = () => {
    return async (dispatch) => {
      dispatch(lockFetch())
      const items = await readItemsMethod()

      const type = `UPDATE_${itemsKey.toUpperCase()}`
      const payload = {}
      payload[itemsKey] = items
      dispatch({ type, payload })
    }
  }

  const addItem = (data) => {
    return async (dispatch, getState) => {
      const item = await createItemMethod(
        data,
        ['common.JsonRequestValidationError']
      )
      if ('errorCode' in item) {
        return
      }
      const items = getState()
        .adminPanelReducer[itemsKey]
        .slice()
      items.push(item)

      const type = `UPDATE_${itemsKey.toUpperCase()}`
      const payload = {}
      payload[itemKey] = item
      payload[itemsKey] = items
      dispatch({ type, payload })
    }
  }

  const updateItem = (item, data) => {
    return async (dispatch, getState) => {
      dispatch(lockFetch())
      const items = getState()
        .adminPanelReducer[itemsKey]
        .slice()
      const updatedItem = await updateItemMethod(item.id, data)
      const itemIdx = items.findIndex(i => i.id === updatedItem.id)
      items[itemIdx] = updatedItem

      const type = `UPDATE_${itemsKey.toUpperCase()}`
      const payload = {}
      payload[itemsKey] = items
      dispatch({ type, payload })
    }
  }

  const deleteItem = (item) => {
    return async (dispatch, getState) => {
      const items = getState()
        .adminPanelReducer[itemsKey]
      await deleteItemMethod(item.id)
      const itemIdx = items.findIndex(i => i.id === item.id)
      items.splice(itemIdx, 1)
      item = null
      if (items.length > 0) {
        item = itemIdx > 0 ? items[itemIdx - 1] : items[0]
      }

      const type = `UPDATE_${itemsKey.toUpperCase()}`
      const payload = {}
      payload[itemKey] = item
      payload[itemsKey] = items
      dispatch({ type, payload })
    }
  }

  return [
    fetchItems,
    addItem,
    updateItem,
    deleteItem
  ]
}

const [
  fetchUsers,
  addUser,
  updateUser,
  deleteUser
] = itemCrudFactory(
  [
    MarkupApi.createUser,
    MarkupApi.getUsers,
    MarkupApi.updateUser,
    MarkupApi.deleteUser
  ],
  'user',
  'users'
)

export {
  fetchUsers,
  addUser,
  updateUser,
  deleteUser
}

const [
  fetchTasks,
  addTask,
  updateTask,
  deleteTask
] = itemCrudFactory(
  [
    MarkupApi.createTask,
    MarkupApi.getTasks,
    MarkupApi.updateTask,
    MarkupApi.deleteTask
  ],
  'task',
  'tasks'
)

export {
  fetchTasks,
  addTask,
  updateTask,
  deleteTask

}

export const assignUser = (user, taskId) => {
  return async (dispatch) => {
    await MarkupApi.assignUser(taskId, user.id)
    dispatch({
      type: 'ASSIGN_USER',
      payload: { userId: user.id, taskId }
    })
  }
}

export const dissUser = (user, taskId) => {
  return async (dispatch) => {
    await MarkupApi.dissociateUser(taskId, user.id)
    dispatch({
      type: 'DISSOCIATE_USER',
      payload: { userId: user.id, taskId }
    })
  }
}

export const setUser = createAction('SET_USER')

export const setTask = createAction('SET_TASK')

export const changeQueryUserId = createAction('CHANGE_QUERY_USER_ID')

export const changeQueryTaskId = createAction('CHANGE_QUERY_TASK_ID')

export const changeQueryDate = createAction('CHANGE_QUERY_DATE')

export const changeQueryBatchId = createAction('CHANGE_QUERY_BATCH_ID')

export const dropQuery = createAction('DROP_QUERY')

export const changeTimetrackingUserId = createAction(
  'CHANGE_TIMETRACKING_USER_ID'
)

export const changeTimetrackingTaskId = createAction(
  'CHANGE_TIMETRACKING_TASK_ID'
)

export const changeTimetrackingFromDate = createAction(
  'CHANGE_TIMETRACKING_FROM_DATE'
)

export const changeTimetrackingToDate = createAction(
  'CHANGE_TIMETRACKING_TO_DATE'
)

export const changeTimetrackingThreshold = createAction(
  'CHANGE_TIMETRACKING_THRESHOLD'
)

export const dropTimetrackingQuery = createAction(
  'DROP_TIMETRACKING_QUERY'
)

export const deleteMarkup = () => {
  return async (dispatch, getState) => {
    const { images, batches, isFetching } = getState().adminPanelReducer
    if (isFetching || images === null) {
      return
    }
    dispatch(lockFetch())
    const { batchId, page } = getState().adminPanelReducer.markupQuery
    for (const img of images) {
      await MarkupApi.updateImage(img.id, null)
    }
    await MarkupApi.updateBatch(batchId, 'not_assigned')
    const batchIndex = batches.findIndex(b => b.id === batchId)
    dispatch(loadPage(page, batchIndex))
  }
}

const loadPageFetch = async (page, userId, taskId, date) => {
  const query = filterNullValues({
    page,
    user_id: userId,
    task_id: taskId,
    markup_date: toUnixTime(date)
  })

  // TODO: check if error really occures
  let resp = await MarkupApi.getBatches(query, ['common.PaginationError'])
  if ('errorCode' in resp) {
    if (query.page !== 0) {
      query.page--
    }
    resp = await MarkupApi.getBatches(query, ['common.PaginationError'])
  }
  return resp
}

export const loadPage = (page, batchIndex = 0) => {
  return async (dispatch, getState) => {
    const { userId, taskId, date } = getState().adminPanelReducer.markupQuery
    const resp = await loadPageFetch(page, userId, taskId, date)
    const { data, pagination } = resp
    if (batchIndex >= data.length) {
      batchIndex = data.length - 1
    }
    const batchId = batchIndex > -1 ? data[batchIndex].id : null

    batch(() => {
      dispatch({
        type: 'SET_PAGINATION_DATA',
        payload: {
          page: pagination.page,
          totalPages: pagination.total_pages,
          batches: data,
          batchId
        }
      })
      dispatch(unlockFetch())
    })
  }
}

export const getBatchImages = (batchId) => {
  return async (dispatch) => {
    if (batchId === null) {
      return
    }
    const images = await MarkupApi.getImages(batchId)
    dispatch({
      type: 'ADD_BATCH_IMAGES',
      payload: {
        images
      }
    })
  }
}

const calcPage = (batches, page, prevId, bias) => {
  const newBatchIndex = batches.findIndex(b => b.id === prevId) + bias
  if (newBatchIndex < 0) {
    page--
  } else if (newBatchIndex >= batches.length) {
    page++
  }
  return page
}

const selectBiasedBatch = (bias) => {
  return async (dispatch, getState) => {
    const { batches, markupQuery } = getState().adminPanelReducer
    const { page, batchId, totalPages } = markupQuery
    const newPage = calcPage(batches, page, batchId, bias)
    if (newPage < 0 || newPage >= totalPages) {
      return
    }

    if (newPage !== page) {
      const { userId, taskId, date } = markupQuery
      const { data, pagination } = await loadPageFetch(newPage, userId, taskId, date)
      const newBatchIndex = newPage < page ? data.length - 1 : 0

      dispatch({
        type: 'SET_PAGINATION_DATA',
        payload: {
          page: pagination.page,
          totalPages: pagination.total_pages,
          batches: data,
          batchId: data[newBatchIndex].id
        }
      })
    } else {
      const newBatchIndex = batches.findIndex(b => b.id === batchId) + bias
      dispatch({
        type: 'CHANGE_QUERY_BATCH_ID',
        payload: batches[newBatchIndex].id
      })
    }
  }
}

export const selectPrevBatch = () => {
  return selectBiasedBatch(-1)
}

export const selectNextBatch = () => {
  return selectBiasedBatch(1)
}

const setTimetrackingData = createAction(
  'SET_TIMETRACKING_DATA'
)

const roundDateToMidnight = (date) => new Date(Math.floor(date / 86400000) * 86400000)

export const loadAnalytics = () => {
  return async (dispatch, getState) => {
    let {
      userId,
      taskId,
      from,
      to: viewTo,
      timeThreshold
    } = getState().adminPanelReducer.timetrackingQuery

    let to = new Date(viewTo)
    to.setDate(to.getDate() + 1)
    from = roundDateToMidnight(from)
    to = roundDateToMidnight(to)

    const query = filterNullValues({
      task_id: taskId,
      from: toUnixTime(from),
      to: toUnixTime(to),
      time_threshold: timeThreshold
    })

    const timetrackingData = await MarkupApi.getUserTimetracking(userId, query)
    dispatch(setTimetrackingData(timetrackingData))
  }
}
