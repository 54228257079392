import React from 'react'

import BaseHistogram from './BaseHistogram'

const ThresholdExceedHistogram = ({ data }) => (
  <BaseHistogram
    title='Количество превышений порога по времени'
    color='#E76F51'
    data={data}
    dataKey='threshold_exceed_cnt'
    totalLabel='Всего превышений порога по времени'
    totalDataKey='total_threshold_exceed_cnt'
  />
)

export default ThresholdExceedHistogram
