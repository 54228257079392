import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'

import history from '../history'
import reducer from '../reducers'

const createProdStore = () => (
  createStore(
    reducer,
    applyMiddleware(
      thunkMiddleware,
      routerMiddleware(history)
    )
  )
)

const API_PREFIX = window.API_PREFIX
const SENTRY_URL = window.SENTRY_URL

export default {
  API_PREFIX,
  SENTRY_URL,
  createStore: createProdStore
}
