import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import styles from './sequence-task.module.css'

const HotkeyEnum = {
  skip: 32,
  save: 13,
  goBack: 8
}

export default function SequenceTaskButtons ({
  batchImages,
  disabled,
  handleHistoryBackButtonClick
}) {
  const dispatch = useDispatch()
  const [images, setImages] = useState(null)

  const goBackButton = useRef()
  const saveButton = useRef()
  const skipButton = useRef()

  useEffect(() => {
    if (batchImages !== null) {
      if (images === null) {
        const initImages = batchImages.slice()
        initImages.forEach((img) => {
          if (img.markup === null) {
            img.markup = {
              marked: false
            }
          }
        })
        dispatch({
          type: 'INIT_MARKUP',
          initImages: initImages
        })
        setImages(batchImages)
      }
    } else {
      if (images !== null) {
        setImages(null)
      }
    }
  }, [batchImages, dispatch, images])

  useEffect(() => {
    const handleHotkeys = (e) => {
      switch (e.keyCode) {
        case HotkeyEnum.skip:
          skipButton.current.click()
          break
        case HotkeyEnum.goBack:
          goBackButton.current.click()
          break
        case HotkeyEnum.save:
          saveButton.current.click()
          break

        default:
          break
      }
    }

    window.addEventListener('keydown', handleHotkeys)
    return () => {
      window.removeEventListener('keydown', handleHotkeys)
    }
  }, [images, dispatch, disabled])

  return (
    <Row className='mb-0 mt-3'>
      <Col>
        <div className={styles['button-container']}>
          <div className={styles['button-hint']}>Backspace</div>
          <Button
            disabled={disabled}
            ref={goBackButton}
            variant='outline-info'
            onClick={handleHistoryBackButtonClick}
          >
            Назад
          </Button>
        </div>
      </Col>
      <Col>
        <div className={styles['button-container']}>
          <div className={styles['button-hint']}>Space</div>
          <Button
            disabled={disabled}
            ref={skipButton}
            variant='outline-info'
            onClick={() => dispatch({ type: 'SKIP_BATCH' })}
          >
            Пропустить
          </Button>
        </div>
      </Col>
      <Col>
        <div className={styles['button-container']}>
          <div className={styles['button-hint']}>Enter</div>
          <Button
            disabled={disabled}
            ref={saveButton}
            variant='outline-info'
            onClick={() => dispatch({ type: 'SAVE_MARKUP' })}
          >
            Готово
          </Button>
        </div>
      </Col>
    </Row>
  )
}
