import React from 'react'
import TimetrackingQuerySelector from './TimetrackingQuerySelector/component'

const SideBar = () => (
  <div className='mt-2'>
    <TimetrackingQuerySelector />
  </div>
)

export default SideBar
