export const taskDataSelector = state => {
  const { batches, markupQuery, tasks } = state.adminPanelReducer
  const { batchId } = markupQuery

  if (batchId === null || batches === null) {
    return null
  }
  if (tasks === null) {
    return null
  }

  const batch = batches.filter(
    b => b.id === batchId
  )[0]
  const task = tasks.filter(
    t => t.id === batch.task_id
  )[0]
  return task
}
