import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTask, updateTask } from '../../../../actions/adminPanel'
import UserManager from './UserManager'
import TaskMarkupDownloader from './TaskMarkupDownloader'

import styles from './task-panel.module.css'

const initialState = {
  name: ''
}

export default function TaskForm ({ task }) {
  const [formData, setFormData] = useState(Object.assign({}, initialState))

  const [showModal, setShowModal] = useState(false)

  const isFetching = useSelector(
    state => state.adminPanelReducer.isFetching
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setFormData(Object.assign({}, initialState))
  }, [task])

  let fieldsEmpty = true
  for (const [key] of Object.entries(formData)) {
    if (formData[key] !== '') {
      fieldsEmpty = false
      break
    }
  }
  const canSave = !fieldsEmpty

  const saveData = async () => {
    const data = {}
    for (const [key, value] of Object.entries(formData)) {
      if (formData[key] !== '') {
        data[key] = value
      }
    }
    dispatch(updateTask(task, data))
  }

  const removeTask = async () => {
    dispatch(deleteTask(task))
    setShowModal(false)
  }

  const updateFormField = (key, e) => {
    const newFormData = { ...formData }
    newFormData[key] = e.target.value
    setFormData(newFormData)
  }

  return (
    <Col className='mt-2 col-8 offset-2'>
      <Form>
        <Form.Group key='Название'>
          <Form.Label>Название</Form.Label>
          <Form.Control
            value={formData.name}
            onChange={(e) => updateFormField('name', e)}
            placeholder={task.name}
          />
        </Form.Group>
        <Form.Group key='Номер задания'>
          <Form.Label>Номер задания</Form.Label>
          <Form.Control
            disabled
            placeholder={task.id}
          />
        </Form.Group>
        <Form.Group key='Тип разметчика'>
          <Form.Label>Тип разметчика</Form.Label>
          <Form.Control
            disabled
            placeholder={task.markupLabel}
          />
        </Form.Group>
        <Form.Group key='Количество изображений в батче'>
          <Form.Label>Количество изображений в батче</Form.Label>
          <Form.Control
            disabled
            placeholder={task.batchSize === null ? 'Варьируется' : task.batchSize}
          />
        </Form.Group>

        <Container className='mt-1 mb-1'>
          <Row>
            <Button
              className='mr-auto'
              variant='success'
              disabled={!canSave || isFetching}
              onClick={saveData}
            >
              Сохранить
            </Button>
            <Button
              variant='danger'
              disabled={isFetching}
              onClick={() => setShowModal(true)}
            >
              Удалить
            </Button>
          </Row>
        </Container>
      </Form>
      <div className='mt-3'>
        <TaskMarkupDownloader taskId={task.id} />
      </div>
      <Container className={styles['user-manager']}>
        <UserManager task={task} />
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление задания</Modal.Title>
        </Modal.Header>
        <Modal.Body>Все пользователи будут откреплены. Вы уверены?</Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isFetching}
            type='submit'
            variant='danger'
            onClick={removeTask}
          >Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}
