import React from 'react'
import { Alert } from 'react-bootstrap'

const QueryHint = () => {
  return (
    <Alert variant='info'>
      <Alert.Heading>Параметры не настроены</Alert.Heading>
      <div>Для построения статистики необходимо настроить все параметры</div>
    </Alert>
  )
}

export default QueryHint
