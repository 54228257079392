import { initEffect, loadEffect, saveEffect } from '../../actions/taskCore'

// markup states
const INIT = 'INIT'
const LOAD = 'LOAD'
const MARKUP = 'MARKUP'
const SAVE = 'SAVE'
const SKIP = 'SKIP'
const DEFAULT = 'DEFAULT'

// show banner states
const NO_MORE_BATCHES = 'NO_MORE_BATCHES'
const BATCH_NOT_FOUND = 'BATCH_NOT_FOUND'
const BATCH_DISSOCIATED = 'BATCH_DISSOCIATED'

const stateMachineTransitions = {
  DEFAULT: ({ taskId }) => initEffect(taskId),
  INIT: ({ taskId }) => initEffect(taskId),
  LOAD: ({ taskId, state }) => loadEffect(taskId, state),
  SAVE: ({ state }) => saveEffect(state, 'done'),
  SKIP: ({ state }) => saveEffect(state, 'skipped')
}

const getTransition = (state) => {
  if (state in stateMachineTransitions) {
    return stateMachineTransitions[state]
  } else {
    return null
  }
}

export default {
  getTransition,
  INIT,
  LOAD,
  MARKUP,
  SAVE,
  SKIP,
  DEFAULT,
  NO_MORE_BATCHES,
  BATCH_NOT_FOUND,
  BATCH_DISSOCIATED

}
