import React from 'react'
import { FormControl } from 'react-bootstrap'

import './style.css'

const DateSelector = ({ date, handleDateChange: handler }) => {

  const handleDateChange = (e) => {
    const date = e.target.value !== '' ? (
      new Date(e.target.value)
    ) : new Date()
    handler(date)
  }

  // NOTE: yyyy-mm-dd date
  const dateValue = date !== null ? date.toISOString().split('T')[0] : null

  return (
    <FormControl
      type='date'
      className='datepicker'
      value={dateValue}
      onChange={handleDateChange}
    />
  )
}

export default DateSelector
