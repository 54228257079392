import React from 'react'
import { Container } from 'react-bootstrap'
import Plot from '../../../../../Utils/Plot'
import { getPlotData } from './utils'

const buildSpentTimeString = ts => {
  const secs = (ts % 60).toString().padStart(2, '0')
  ts = parseInt(ts / 60)
  const mins = (ts % 60).toString().padStart(2, '0')
  const hours = parseInt(ts / 60)
  return `${hours}:${mins}:${secs}`
}

const SpentTimeHistogram = ({ data }) => {
  let { x, y, total } = getPlotData(data, 'delta', 'total_delta')

  total = buildSpentTimeString(parseInt(total))

  const yLabels = y.map(item => new Date(item * 1000).toISOString().substr(11, 8))
  const yValues = y.map(item => new Date(item * 1000).getTime())

  const layout = {
    width: 800,
    height: 400,
    title: 'Статистика по затраченному времени',
    xaxis: {
      tickformat: '%d/%m',
      tickmode: 'linear'
    },
    yaxis: {
      tickvals: yValues,
      ticktext: yLabels
    }
  }

  const plotData = [
    {
      x,
      y: yValues,
      type: 'bar',
      marker: { color: '#264653' }
    }
  ]

  return (
    <Container fluid className='mb-5'>
      <Plot
        data={plotData}
        layout={layout}
      />
      <Container fluid>
        {`Всего затрачено времени: ${total}`}
      </Container>
    </Container>
  )
}

export default SpentTimeHistogram
