import dev from './dev'
import prod from './prod'

const configs = { dev, prod }
const currentConfig = configs[process.env.REACT_APP_STAGE]

const API_PREFIX = currentConfig.API_PREFIX
const SENTRY_URL = currentConfig.SENTRY_URL
const createStore = currentConfig.createStore

export { API_PREFIX, SENTRY_URL, createStore }
