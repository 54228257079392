import { push } from 'connected-react-router'
import React, { useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import MarkupApi from '../../markupApi'

export default function Login () {
  const [loggingIn, setLogginIn] = useState(false)
  const [emailColorCls, highlightEmail] = useState('')
  const [pwdColorCls, highlightPassword] = useState('')

  const emailRef = React.createRef()
  const passwordRef = React.createRef()

  const dispatch = useDispatch()

  const handleLogin = async (e) => {
    e.preventDefault()
    const email = emailRef.current.value
    const password = passwordRef.current.value

    setLogginIn(true)
    const expectedCodes = [
      'common.JsonRequestValidationError',
      'user.UserNotFound',
      'auth.InvalidPassword'
    ]
    const resp = await MarkupApi.login(
      email,
      password,
      expectedCodes
    )
    setLogginIn(false)

    if ('errorCode' in resp) {
      switch (resp.msg) {
        case 'common.JsonRequestValidationError':
          highlightEmail('bg-danger')
          break
        case 'user.UserNotFound':
          highlightEmail('bg-danger')
          break
        case 'auth.InvalidPassword':
          highlightPassword('bg-danger')
          break
        default:
          break
      }
    } else {
      localStorage.setItem('user_id', resp.user_id)
      localStorage.setItem('access_token', resp.access_token)
      const params = (new URL(document.location)).searchParams
      let url = params.get('next')
      if (url === null) {
        url = '/'
      }
      dispatch(push(url))
    }
  }

  return (
    <Container className='mt-4'>
      <Form>
        <Form.Group>
          <Form.Control
            className={emailColorCls}
            ref={emailRef}
            type='email'
            onClick={() => highlightEmail('')}
            onInput={() => highlightEmail('')}
            placeholder='Введите email'
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            className={pwdColorCls}
            ref={passwordRef}
            type='password'
            onClick={() => highlightPassword('')}
            onInput={() => highlightPassword('')}
            placeholder='Введите пароль'
          />
        </Form.Group>
        <Form.Group>
          <Button
            type='submit'
            onClick={handleLogin}
            disabled={loggingIn}
          >Вход
          </Button>
        </Form.Group>
      </Form>
    </Container>
  )
}
