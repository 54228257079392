import { push } from 'connected-react-router'
import { createAction } from 'redux-actions'

import stateMachine from '../components/TaskCore/taskCoreStateMachine'
import { API_PREFIX } from '../config'
import MarkupApi from '../markupApi'

export const initEffect = (taskId) => {
  return async (dispatch) => {
    dispatch({ type: 'LOCK_TASKS_FETCH' })
    const taskData = await MarkupApi.getTask(taskId)
    dispatch({
      type: 'INIT_TASK',
      markupType: taskData.markup_type,
      settings: taskData.settings
    })
  }
}

export const loadEffect = (taskId, state) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'LOCK_TASKS_FETCH' })

    let batchId
    let batchData
    if (state.batchId === null) {
      // NOTE: new batch or id direct access handle
      const params = (new URL(document.location)).searchParams
      const idParam = params.get('batch_id')

      if (idParam === null || !state.isFirstLoad) {
        batchData = await MarkupApi.getBatchToMarkup(
          taskId,
          ['batch.BatchNotFound']
        )
        if ('errorCode' in batchData) {
          dispatch({ type: 'NO_MORE_BATCHES' })
          return
        }
      } else {
        batchData = await MarkupApi.getBatch(
          idParam,
          ['batch.BatchNotFound']
        )
        if ('errorCode' in batchData) {
          dispatch({ type: 'BATCH_NOT_FOUND' })
          return
        }
      }
      batchId = batchData.id

      if (Number(idParam) !== batchId) {
        const baseUrl = getState().router.location.pathname
        const newUrl = `${baseUrl}?batch_id=${batchId}`
        dispatch(push(newUrl))
      }
    } else {
      // NOTE: history pop handle
      batchId = state.batchId
      batchData = await MarkupApi.getBatch(
        batchId,
        ['batch.BatchAccessDenied']
      )
      if ('errorCode' in batchData) {
        dispatch({ type: 'BATCH_DISSOCIATED' })
        return
      }
    }
    const imagesData = await MarkupApi.getImages(batchId)
    for (const img of imagesData) {
      // prefetch image (for HTTP caching)
      await (new Promise(resolve => {
        const image = new Image()
        image.src = `${API_PREFIX}/images/${img.id}/image`
        image.onload = () => {
          resolve()
        }
      }))
    }

    dispatch({
      type: 'SET_MARKUP_CONTEXT',
      batchId: batchId,
      images: imagesData
    })
  }
}

export const saveEffect = (state, batchState) => {
  return async (dispatch) => {
    for (const img of state.batchImages) {
      if (img.markup !== null) {
        await MarkupApi.updateImage(img.id, img.markup)
      }
    }
    await MarkupApi.updateBatch(state.batchId, batchState)

    dispatch({
      type: 'LOAD_NEW_BATCH'
    })
  }
}

export const stateManagementEffect = (taskId) => {
  return (dispatch, getState) => {
    const state = getState().taskCoreReducer
    const stateMachineData = { taskId, state }
    const transition = stateMachine.getTransition(state.type)
    if (transition !== null) {
      dispatch(transition(stateMachineData))
    }
  }
}

export const registerGoBackEffect = (adminMode, taskId) => {
  return (dispatch) => {
    window.onpopstate = () => {
      if (adminMode) {
        const params = (new URL(document.location)).searchParams
        const userId = params.get('user_id')
        if (userId !== null) {
          dispatch(
            push(`/admin/tasks/${taskId}/users/${userId}/markup`)
          )
        }
        return
      }
      const params = (new URL(document.location)).searchParams
      const idParam = params.get('batch_id')
      if (idParam === null) {
        dispatch(push('/tasks'))
      }
      dispatch({
        type: 'GO_BACK',
        batchId: idParam
      })
    }
  }
}

export const openTask = createAction('OPEN_TASK')

export const dropTask = createAction('DROP_TASK')
