import React, { useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap'

import { API_PREFIX } from '../../../../config'

const BatchState = {
  ALL: {
    queryParam: null,
    label: 'Все'
  },
  NOT_ASSIGNED: {
    queryParam: 'not_assigned',
    label: 'Не назначенные'
  },
  MARKING: {
    queryParam: 'marking',
    label: 'Размечаемые'
  },
  SKIPPED: {
    queryParam: 'skipped',
    label: 'Пропущенные'
  },
  DONE: {
    queryParam: 'done',
    label: 'Готовые'
  }
}

export default function TaskMarkupDownloader (props) {
  const [showModal, setShowModal] = useState(false)
  const [batchState, setBatchState] = useState(BatchState.ALL.queryParam)

  const downloadMarkup = (batchState, e) => {
    e.preventDefault()
    let attachUrl = `${API_PREFIX}/tasks/${props.taskId}/markup`
    if (batchState !== null) {
      attachUrl += `?batch_state=${batchState}`
    }
    window.location.replace(attachUrl)
  }

  let isFirstListItem = true
  return (
    <Container className='text-center'>
      <Button
        variant='success'
        onClick={() => setShowModal(true)}
      >
        Скачать разметку
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>Выберите состояние батчей</Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Control
              as='select'
              className='mb-3'
              onChange={(e) => setBatchState(e.target.value)}
            >
              {Object.entries(BatchState).map((key, value) => {
                const [, stateInfo] = key
                const { queryParam, label } = stateInfo
                if (isFirstListItem) {
                  isFirstListItem = false
                  return <option key={label} value={queryParam} selected>{label}</option>
                } else {
                  return <option key={label} value={queryParam}>{label}</option>
                }
              })}
            </Form.Control>
            <Button
              type='submit'
              variant='success'
              onClick={(e) => downloadMarkup(batchState, e)}
            >
              Cкачать
            </Button>
          </Modal.Body>
        </Form>
      </Modal>
    </Container>
  )
}
