import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import history from '../history'
import reducer from '../reducers'

const createDevStore = () => (
  createStore(
    reducer,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        routerMiddleware(history)
      )
    )
  )
)

const API_PREFIX = 'http://localhost:8000/api/v1'
const SENTRY_URL = null

export default {
  API_PREFIX,
  SENTRY_URL,
  createStore: createDevStore
}
