import React from 'react'
import { useSelector } from 'react-redux'

import AdminPanelBanner from './AdminPanelBanner'
import AdminPanelMenu from './AdminPanelMenu'
import AdminPanelNavbar from './AdminPanelNavbar'
import { currentUserSelector } from './selectors'

export default function AdminPanel (props) {
  const currentUser = useSelector(currentUserSelector)

  return currentUser.is_admin ? (
    <div>
      <AdminPanelNavbar {...props} currentUser={currentUser} />
      <AdminPanelMenu {...props} currentUser={currentUser} />
    </div>
  ) : (
    <AdminPanelBanner />
  )
}
