import React, { useState } from 'react'
import { Button, Col, ListGroup, Row, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../../../actions/adminPanel'
import { adminPanelSelector } from '../../selectors'

import styles from './user-panel.module.css'
import '../admin-panel-menu.css'
import UserCreateModal from './UserCreateModal'
import UserForm from './UserForm'

export default function UserPanel ({ currentUser }) {
  const [isCreating, setIsCreating] = useState(false)

  const { users, user } = useSelector(adminPanelSelector)
  const dispatch = useDispatch()

  if (user === null) {
    return null
  }

  return (
    <Tab.Container
      activeKey={user.id}
      onSelect={(id) => dispatch(
        setUser(users.filter(u => u.id === Number(id))[0])
      )}
    >
      <Row className='mt-3 ml-1'>
        <Col className='col-12 col-sm-10 offset-sm-1 col-md-3 offset-md-0'>
          <div className='text-center mb-3'>
            <Button
              className={styles['add-btn']}
              variant='outline-success'
              onClick={() => setIsCreating(true)}
            >
              Добавить пользователя
            </Button>
          </div>
          <ListGroup className='panel-tab-items-list'>
            {users.map(u =>
              <ListGroup.Item
                action
                key={u.id}
                eventKey={u.id}
              >
                {u.email}
              </ListGroup.Item>
            )}
          </ListGroup>
        </Col>
        <Col className='col-12 col-sm-12 col-md-9'>
          <Tab.Content>
            <UserForm
              user={user}
              currentUser={currentUser}
            />
          </Tab.Content>
        </Col>
      </Row>
      <UserCreateModal
        show={isCreating}
        createHook={setIsCreating}
      />
    </Tab.Container>
  )
}
