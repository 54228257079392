import React from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { dropQuery } from '../../../../actions/adminPanel'

const DropQueryButton = ({ handleClick }) => {
  const dispatch = useDispatch()

  return (
    <Button
      variant='danger'
      onClick={handleClick}
    >
      Сбросить фильтры
    </Button>
  )
}

export default DropQueryButton
