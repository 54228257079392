import React from 'react'

import BaseHistogram from './BaseHistogram'

const BatchesHistogram = ({ data }) => (
  <BaseHistogram
    title='Статистика по батчам'
    color='#E9C46A'
    data={data}
    dataKey='batches'
    totalLabel='Среднее число батчей в день'
    totalDataKey='avg_batches'
  />
)

export default BatchesHistogram
