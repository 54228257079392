import React, { useContext, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

import ClassesListContext from './taskClassesListContext'

const placeholders = {
  displayText: 'Название кнопки',
  markupLabel: 'Класс для разметки'
}

const ClassInput = React.forwardRef(
  (props, ref) =>
    <Form.Control
      disabled={props.disabled}
      value={props.value}
      onChange={props.handleClassInput}
      placeholder={props.placeholder}
    />
)

const LabelInput = React.forwardRef(
  (props, ref) =>
    <Form.Control
      disabled={props.disabled}
      value={props.value}
      onChange={props.handleLabelInput}
      placeholder={props.placeholder}
    />
)

export default function TaskClassesCreateList () {
  const context = useContext(ClassesListContext)
  const [newItemClass, setNewItemClass] = useState('')
  const [newItemLabel, setNewItemLabel] = useState('')

  const handleClassInput = (e) => {
    setNewItemClass(e.target.value)
  }

  const handleLabelInput = (e) => {
    setNewItemLabel(e.target.value)
  }

  const createItem = () => {
    context.handleCreate({
      markup_label: newItemClass,
      display_text: newItemLabel
    })
    setNewItemClass('')
    setNewItemLabel('')
  }

  const existingRows = []
  const classes = context.settings.classes
  for (let i = 0; i < classes.length; i++) {
    const cls = classes[i]
    existingRows.push(
      <Row className='mt-1'>
        <Col className='col-xs-5 col-sm-5 col-md-5'>
          <LabelInput
            disabled
            value={cls.display_text}
            placeholder={cls.display_text}
          />
        </Col>
        <Col className='col-xs-5 col-sm-5 col-md-5'>
          <ClassInput
            disabled
            value={cls.markup_label}
            placeholder={cls.markup_label}
          />
        </Col>
        <Col className='col-xs-2 col-sm-2 col-md-2'>
          <Button
            block
            variant='danger'
            onClick={() => context.handleDelete(i)}
          >-
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      {existingRows.map((row) => {
        return row
      })}
      <Row className='mt-1'>
        <Col className='col-xs-5 col-sm-5 col-md-5'>
          <LabelInput
            disabled={false}
            value={newItemLabel}
            placeholder={placeholders.displayText}
            handleLabelInput={handleLabelInput}
          />
        </Col>
        <Col className='col-xs-5 col-sm-5 col-md-5'>
          <ClassInput
            disabled={false}
            value={newItemClass}
            placeholder={placeholders.markupLabel}
            handleClassInput={handleClassInput}
          />
        </Col>
        <Col className='col-xs-2 col-sm-2 col-md-2'>
          <Button
            block
            variant='success'
            onClick={createItem}
          >+
          </Button>
        </Col>
      </Row>
    </div>
  )
}
