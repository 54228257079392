import React from 'react'
import { Alert } from 'react-bootstrap'

export default function NoTasksBanner () {
  return (
    <div className='text-center'>
      <Alert variant='info'>
        <Alert.Heading>Заданий нет</Alert.Heading>
        <hr />
        <div>
          В данный момент вы не прикреплены ни к одному заданию.
          Для детальной информации свяжитесь с администратором.
        </div>
      </Alert>
    </div>
  )
}
