import React, { useEffect, useRef } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import styles from './classification-task.module.css'

const HotkeyEnum = {
  back: 8,
  skip: 32
}

const MarkupButton = React.forwardRef(
  // FIXME: lint issue, can't use onClick of external component
  (props, ref) => (
    <Button
      onClick={props.handleClick}
      disabled={props.disabled}
      value={props.value}
      variant={props.variant}
      ref={ref}
    >
      {props.children}
    </Button>
  )
)

export default function ClassificationTaskButtons ({
  batchImages,
  settings,
  disabled,
  handleHistoryBackButtonClick
}) {
  const dispatch = useDispatch()

  const backBtn = useRef()
  const skipBtn = useRef()
  const refs = []

  const pushRef = (ref) => {
    refs.push(ref)
  }

  useEffect(() => {
    const handleHotkeys = (e) => {
      switch (e.keyCode) {
        case HotkeyEnum.back:
          backBtn.current.click()
          return
        case HotkeyEnum.skip:
          skipBtn.current.click()
          return
        default:
          break
      }

      const settingsCap = settings.classes.length
      if (!Number.isNaN(e.key)) {
        const key = Number(e.key) - 1
        if (key < settingsCap) {
          const btn = refs[key]
          btn.click()
        }
      }
    }

    window.addEventListener('keydown', handleHotkeys)
    return () => {
      window.removeEventListener('keydown', handleHotkeys)
    }
  }, [settings, refs])

  const handleSave = async (e) => {
    const imageId = batchImages[0].id
    const markup = {
      class: e.target.value
    }

    dispatch({
      type: 'SET_MARKUP',
      imageId: imageId,
      markup: markup
    })
    dispatch({
      type: 'SAVE_MARKUP'
    })
  }

  const classes = settings.classes
  const buttons = []
  for (let i = 0; i < classes.length; i++) {
    const cls = classes[i]
    let markup = null
    if (batchImages !== null) {
      markup = batchImages[0].markup
    }
    let color
    if (markup === null) {
      color = 'outline-info'
    } else {
      color = cls.markup_label === markup.class ? 'info' : 'outline-info'
    }
    buttons.push(
      <Col className='col-xs-3 col-sm-3 col-md-3'>
        <div className={styles['button-container']}>
          <div className={styles['button-hint']}>{i + 1}</div>
          <MarkupButton
            disabled={disabled}
            ref={pushRef}
            value={cls.markup_label}
            variant={color}
            handleClick={handleSave}
          >
            {cls.display_text}
          </MarkupButton>
        </div>
      </Col>
    )
  }

  return (
    <div className='mt-3'>
      <Container>
        <Row>
          {buttons.map((btn) => {
            return btn
          })}
        </Row>
      </Container>
      <Row className='mb-0 mt-5'>
        <Col>
          <div className={styles['button-container']}>
            <div className={styles['button-hint']}>Backspace</div>
            <Button
              ref={backBtn}
              variant='outline-info'
              onClick={handleHistoryBackButtonClick}
            >
              Назад
            </Button>
          </div>
        </Col>
        <Col>
          <div className={styles['button-container']}>
            <div className={styles['button-hint']}>Space</div>
            <Button
              ref={skipBtn}
              variant='outline-info'
              onClick={() => dispatch({ type: 'SKIP_BATCH' })}
            >
              Пропустить
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
