import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTasks, fetchUsers } from '../../../actions/adminPanel'
import { adminPanelSelector } from '../selectors'
import { pathnameSelector } from './selectors'
import TaskPanel from './TaskPanel'
import TimetrackingPanel from './TimetrackingPanel/component'
import UserPanel from './UserPanel'
import MarkupPanel from './MarkupPanel/component'

export default function AdminPanelMenu (props) {
  const { currentUser } = props
  const { users, tasks } = useSelector(adminPanelSelector)
  const pathname = useSelector(pathnameSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (users === null) {
      dispatch(fetchUsers())
    }
  }, [users, dispatch])

  useEffect(() => {
    if (tasks === null) {
      dispatch(fetchTasks())
    }
  }, [tasks, dispatch])

  const tabToQueryString = (data) => {
    const url = pathname + `?tab=${data}`
    dispatch(push(url))
  }

  const params = (new URL(document.location)).searchParams
  let tab = params.get('tab')
  if (tab === null) {
    tab = 'tasks'
  }

  const renderView = users !== null && tasks !== null

  return renderView ? (
    <Tabs
      defaultActiveKey={tab}
      onSelect={tabToQueryString}
    >
      <Tab eventKey='users' title='Пользователи'>
        <Container className='admin-container'>
          <UserPanel currentUser={currentUser} />
        </Container>
      </Tab>
      <Tab eventKey='tasks' title='Задания'>
        <Container className='admin-container'>
          <TaskPanel currentUser={currentUser} />
        </Container>
      </Tab>
      <Tab eventKey='markup' title='Разметка'>
        <Container className='admin-container'>
          <MarkupPanel />
        </Container>
      </Tab>
      <Tab eventKey='timetracking' title='Таймтрекинг'>
        <Container className='admin-container'>
          <TimetrackingPanel />
        </Container>
      </Tab>
    </Tabs>
  ) : null
}
