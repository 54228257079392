import React from 'react'

import BatchesHistogram from './Histograms/BatchesHistogram'
import MarkupSpeedHistogram from './Histograms/MarkupSpeedHistogram'
import SpentTimeHistogram from './Histograms/SpentTimeHistogram'
import ThresholdExceedHistogram from './Histograms/ThresholdExceedHistogram'

const Viewer = ({ data }) => (
  <>
    <SpentTimeHistogram data={data} />
    <hr color='#bfbfbf' />
    <BatchesHistogram data={data} />
    <hr color='#bfbfbf' />
    <MarkupSpeedHistogram data={data} />
    <hr color='#bfbfbf' />
    <ThresholdExceedHistogram data={data} />
  </>
)

export default Viewer
