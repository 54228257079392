import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router'
import { dropTask, registerGoBackEffect, stateManagementEffect } from '../../actions/taskCore'
import MarkupApi from '../../markupApi'
import TaskFactory from '../Tasks'
import BannerFactory from './Banners'

import styles from './task-core.module.css'
import stateMachine from './taskCoreStateMachine'

export default function TaskCore (props) {
  const state = useSelector(
    state => state.taskCoreReducer
  )
  const dispatch = useDispatch()
  const { taskId } = useParams()

  const { adminMode } = props

  useEffect(() => {
    if (state.isFetching) {
      return
    }
    dispatch(stateManagementEffect(taskId))
  }, [state, dispatch, taskId])

  useEffect(() => {
    dispatch(registerGoBackEffect(adminMode, taskId))
  }, [dispatch, adminMode, taskId])

  const handleReturn = async () => {
    await MarkupApi.updateBatch(state.batchId, 'skipped')
    dispatch(dropTask())
    dispatch(push('/tasks'))
  }

  // TODO: fix tugged banner appearance
  const Banner = BannerFactory.createBanner(state.type)
  if (Banner !== null) {
    return <Banner {...props} />
  }

  // returned when task type is unknown
  if (state.markupType === null) {
    return null
  }
  // create custom batch and buttons
  const [Batch, Buttons] = TaskFactory.createTask(state.markupType)

  // set visibility for loaded batch
  let cls = styles['batch-container']
  if (state.type === stateMachine.MARKUP) {
    cls = styles['loaded-batch-container']
  }

  return (
    <div className='mt-3'>
      {!adminMode ? (
        <Button
          onClick={handleReturn}
          className='mr-5 p-2 float-right'
          variant='outline-primary'
        >
          <FontAwesomeIcon icon={faHome} size='lg' />
        </Button>
      ) : null}
      <Container className='text-center'>
        <div>
          <div className={cls}>
            {state.type === stateMachine.MARKUP ? <Batch {...props} {...state} /> : null}
          </div>
          <Buttons
            {...props}
            {...state}
            disabled={state.type !== stateMachine.MARKUP || adminMode}
          />
        </div>
      </Container>
    </div>
  )
}
