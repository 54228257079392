import { fromUnixTime } from '../../../../../../utils'

export const getPlotData = (data, dataKey, totalDataKey) => {
  const x = []
  const y = []

  for (const dayInfo of data.days_info) {
    const day = new Date(
      fromUnixTime(dayInfo.date)
    ).toISOString().split('T')[0]

    x.push(day)
    y.push(dayInfo[dataKey])
  }

  return {
    x,
    y,
    total: data[totalDataKey]
  }
}
