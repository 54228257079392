import React from 'react'
import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { changeQueryBatchId } from '../../../../../../actions/adminPanel'

const BatchListItem = ({
  batch,
  selectedBatchId
}) => {
  const dispatch = useDispatch()

  return (
    <ListGroup.Item
      active={batch.id === selectedBatchId}
      onClick={() => dispatch(changeQueryBatchId(batch.id))}
      key={batch.id}
    >
      <Row className='row'>
        <Col>
          <Container>
            {batch.id}
          </Container>
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

export default BatchListItem
