import React from 'react'
import { Alert } from 'react-bootstrap'

import './styles.css'

export default () => (
  <div className='text-center'>
    <Alert variant='danger' className='error-handler'>
      <Alert.Heading>Ошибка сети</Alert.Heading>
      <div>
        Проверьте подключение к Интернету или свяжитесь с администратором.
      </div>
    </Alert>
  </div>
)
