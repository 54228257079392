import React, { useState } from 'react'

import { Button, Col, ListGroup, Row, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setTask } from '../../../../actions/adminPanel'
import { TASK_MARKUP_TYPE } from '../../../Tasks'
import { adminPanelSelector } from '../../selectors'

import styles from './task-panel.module.css'
import '../admin-panel-menu.css'
import TaskCreateModal from './TaskCreateModal'
import TaskForm from './TaskForm'

export default function TaskPanel () {
  const [isCreating, setIsCreating] = useState(false)

  const { tasks, task } = useSelector(adminPanelSelector)
  const dispatch = useDispatch()

  if (task !== null) {
    if ('batchSize' in TASK_MARKUP_TYPE[task.markup_type]) {
      task.batchSize = TASK_MARKUP_TYPE[task.markup_type].batchSize
    } else {
      task.batchSize = task.batch_size
    }
    task.markupLabel = TASK_MARKUP_TYPE[task.markup_type].name
  }

  const taskList = task !== null ? (
    <ListGroup className='panel-tab-items-list'>
      {tasks.map(t =>
        <ListGroup.Item
          action
          key={t.id}
          eventKey={t.id}
        >
          {t.name}
        </ListGroup.Item>
      )}
    </ListGroup>
  ) : null

  const taskForm = task !== null ? (
    <TaskForm
      task={task}
    />
  ) : null

  const notNullTaskProps = {}
  if (task !== null) {
    notNullTaskProps.activeKey = task.id
  }

  return (
    <Tab.Container
      {...notNullTaskProps}
      onSelect={(id) => dispatch(
        setTask(tasks.filter(t => t.id === Number(id))[0])
      )}
    >
      <Row className='mt-3 ml-1'>
        <Col className='col-8 offset-2 col-md-3 offset-md-0'>
          <div className='text-center mb-3'>
            <Button
              className={styles['add-btn']}
              variant='outline-success'
              onClick={() => setIsCreating(true)}
            >
              Добавить задание
            </Button>
          </div>
          {taskList}
        </Col>
        <Col className='col-12 col-sm-12 col-md-9'>
          <Tab.Content>
            {taskForm}
          </Tab.Content>
        </Col>
      </Row>
      <TaskCreateModal
        show={isCreating}
        createHook={setIsCreating}
      />
    </Tab.Container>
  )
}
