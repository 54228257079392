import React from 'react'
import { Col, ListGroup } from 'react-bootstrap'

const ItemsList = ({
  topic,
  items,
  handleDoubleClick,
  itemKey
}) => {
  return (
    <Col>
      <div>{topic}</div>
      <ListGroup className='tab-items-list'>
        {items.map(
          i => (
            <ListGroup.Item
              action
              key={i.id}
              onDoubleClick={() => handleDoubleClick(i)}
            >
              {i[itemKey]}
            </ListGroup.Item>
          )
        )}
      </ListGroup>
    </Col>
  )
}

export default ItemsList
