import { goBack } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import { loadUserDataEffect } from '../actions/common'
import AdminPanel from './AdminPanel'

import './App.css'
import Login from './Login'
import Logout from './Logout'
import NetworkErrorHandler from './NetworkErrorHandler/component'
import TaskCore from './TaskCore'
import TaskMenu from './TaskMenu'

const App = () => {
  const currentUser = useSelector(
    state => state.userReducer.currentUser
  )
  const pathname = useSelector(
    state => state.router.location.pathname
  )
  const [isFetching, setIsFetching] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const dispatch = useDispatch()

  const userId = localStorage.getItem('user_id')
  const token = localStorage.getItem('access_token')

  useEffect(() => {
    if (pathname === '/login' || pathname === '/logout') {
      return
    }
    if (currentUser !== null || isFetching) {
      return
    }
    setIsFetching(true)
    dispatch(loadUserDataEffect(userId, token))
    setIsFetching(false)
  }, [isFetching, setIsFetching, currentUser, dispatch, userId, token, pathname])

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
    }
  }, [isFirstLoad, setIsFirstLoad])

  return (
    <Switch>
      {isFirstLoad && pathname === '/network_error' ? (
        <Redirect to='/' />
      ) : null}
      <Route
        path='/network_error'
        exact
        component={NetworkErrorHandler}
      />
      <Route
        path='/login'
        exact
        component={Login}
      />
      {currentUser !== null && !isFetching ? (
        <Switch>
          <Route
            path='/tasks'
            exact
            component={TaskMenu}
          />
          <Route
            path='/tasks/:taskId'
            exact
            render={() => (
              <TaskCore
                adminMode={false}
                handleHistoryBackButtonClick={() => dispatch(goBack())}
              />
            )}
          />
          <Route
            path='/admin'
            component={AdminPanel}
          />
          <Route
            path='/logout'
            exact
            component={Logout}
          />
          <Redirect push to='/tasks' />
        </Switch>
      ) : null}
    </Switch>
  )
}

export default withRouter(App)
