import * as Sentry from '@sentry/browser'
import { handleActions } from 'redux-actions'

const defaultState = {
  currentUser: null
}

export default handleActions({
  INIT_CURRENT_USER: (state, action) => {
    const currentUser = action.payload

    Sentry.configureScope((scope) => {
      scope.setUser({
        email: currentUser.email
      })
    })
    return { ...state, currentUser }
  }
}, defaultState)
