import { push } from 'connected-react-router'
import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

export default function TaskMenuNavbar () {
  const currentUser = useSelector(
    state => state.userReducer.currentUser
  )
  const dispatch = useDispatch()

  return (
    <Navbar bg='dark' variant='dark'>
      <Nav className='mr-auto' />
      <Nav>
        {currentUser.is_admin ? (
          <Nav.Link
            onClick={() => { dispatch(push('/admin')) }}
          >
            Админ панель
          </Nav.Link>
        ) : null}
        <Nav.Link
          onClick={() => dispatch(push('/logout'))}
        >
          Выйти
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}
