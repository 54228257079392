import React, { useEffect, useRef } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { batch, useDispatch } from 'react-redux'

import styles from './binary-task.module.css'

const HotkeyEnum = {
  yes: 87,
  no: 68,
  back: 8,
  skip: 32
}

export default function BinaryTaskButtons ({
  batchImages,
  disabled,
  handleHistoryBackButtonClick
}) {
  const dispatch = useDispatch()

  const yesBtn = useRef()
  const noBtn = useRef()
  const backBtn = useRef()
  const skipBtn = useRef()

  const handleHotkeys = (e) => {
    switch (e.keyCode) {
      case HotkeyEnum.yes:
        yesBtn.current.click()
        break
      case HotkeyEnum.no:
        noBtn.current.click()
        break
      case HotkeyEnum.back:
        backBtn.current.click()
        break
      case HotkeyEnum.skip:
        skipBtn.current.click()
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleHotkeys)
    return () => {
      window.removeEventListener('keydown', handleHotkeys)
    }
  }, [])

  const handleSave = async (value) => {
    const imageId = batchImages[0].id
    const markup = {
      marked: value
    }

    batch(() => {
      dispatch({
        type: 'SET_MARKUP',
        imageId: imageId,
        markup: markup
      })
      dispatch({
        type: 'SAVE_MARKUP'
      })
    })
  }

  let isMarked = null
  if (batchImages !== null) {
    const markup = batchImages[0].markup
    if (markup !== null) {
      isMarked = markup.marked
    }
  }

  return (
    <div>
      <Row className='mt-3 mb-4'>
        <Col>
          <div className={styles['button-container']}>
            <div className={styles['button-hint']}>W</div>
            <Button
              ref={yesBtn}
              disabled={disabled}
              variant={isMarked === true ? 'info' : 'outline-info'}
              onClick={() => handleSave(true)}
            >
              Да
            </Button>
          </div>
        </Col>
        <Col>
          <div className={styles['button-container']}>
            <div className={styles['button-hint']}>D</div>
            <Button
              ref={noBtn}
              disabled={disabled}
              variant={isMarked === false ? 'info' : 'outline-info'}
              onClick={() => handleSave(false)}
            >
              Нет
            </Button>
          </div>
        </Col>
      </Row>
      <Row className='mb-0'>
        <Col>
          <div className={styles['button-container']}>
            <div className={styles['button-hint']}>Backspace</div>
            <Button
              ref={backBtn}
              disabled={disabled}
              variant='outline-info'
              onClick={handleHistoryBackButtonClick}
            >
              Назад
            </Button>
          </div>
        </Col>
        <Col>
          <div className={styles['button-container']}>
            <div className={styles['button-hint']}>Space</div>
            <Button
              ref={skipBtn}
              disabled={disabled}
              variant='outline-info'
              onClick={() => dispatch({ type: 'SKIP_BATCH' })}
            >
              Пропустить
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
