import React from 'react'
import { Container } from 'react-bootstrap'
import Plot from '../../../../../Utils/Plot'
import { getPlotData } from './utils'

const BaseHistogram = ({
  title,
  color,
  data,
  dataKey,
  totalLabel,
  totalDataKey
}) => {
  const layout = {
    width: 800,
    height: 400,
    title,
    xaxis: {
      tickformat: '%d/%m',
      tickmode: 'linear'
    }
  }

  const { x, y, total } = getPlotData(data, dataKey, totalDataKey)

  const plotData = [
    {
      x,
      y,
      type: 'bar',
      marker: { color }
    }
  ]

  return (
    <Container fluid className='mb-5'>
      <Plot
        data={plotData}
        layout={layout}
      />
      <Container fluid>
        {`${totalLabel}: ${total}`}
      </Container>
    </Container>
  )
}

export default BaseHistogram
