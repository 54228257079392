import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeTimetrackingFromDate,
  changeTimetrackingTaskId,
  changeTimetrackingThreshold,
  changeTimetrackingToDate,
  changeTimetrackingUserId,
  dropTimetrackingQuery,
  loadAnalytics
} from '../../../../../../actions/adminPanel'
import { adminPanelSelector } from '../../../../selectors'
import DateSelector from '../../../common/DateSelector/component'
import DropQueryButton from '../../../common/DropQueryButton'
import TaskSelector from '../../../common/TaskSelector'
import UserSelector from '../../../common/UserSelector'

import styles from './style.module.css'

const timetrackingQuerySelector = state => state.adminPanelReducer.timetrackingQuery

const convertTimeThreshold = (time) => {
  const resultTime = []
  const minutes = Math.floor(time / 60)
  const seconds = time % 60

  if (minutes !== 0) {
    resultTime.push(minutes + ' м')
  }

  if (seconds !== 0 || time === 0) {
    resultTime.push(seconds + ' c')
  }

  return resultTime.join(' ')
}

const TimetrackingQuerySelector = () => {
  const { users, tasks } = useSelector(adminPanelSelector)
  const {
    taskId,
    userId,
    from,
    to,
    timeThreshold,
    minThreshold,
    maxThreshold
  } = useSelector(timetrackingQuerySelector)

  const timetrackingData = useSelector(
    state => state.adminPanelReducer.timetrackingData
  )

  let viewTimeThreshold = timeThreshold
  if (timeThreshold === null && timetrackingData !== null) {
    viewTimeThreshold = timetrackingData.avg_threshold
  }

  const dispatch = useDispatch()

  useEffect(
    () => {
      if (userId !== null &&
          taskId !== null &&
          from !== null &&
          to !== null) {
        dispatch(loadAnalytics())
      }
    },
    [
      userId,
      taskId,
      from,
      to,
      timeThreshold
    ]
  )

  const selectorData = { users, tasks, userId, taskId }

  return (
    <div className='text-center mb-3'>
      <div className='mb-4'>
        <UserSelector
          data={selectorData}
          handleIdChange={
            (e) => dispatch(changeTimetrackingUserId(Number(e.target.value)))
          }
        />
        <TaskSelector
          data={selectorData}
          handleIdChange={
            (e) => dispatch(changeTimetrackingTaskId(Number(e.target.value)))
          }
        />
        <div className='mb-3'>
          <DateSelector
            date={from}
            handleDateChange={
              (newDate) => dispatch(changeTimetrackingFromDate(newDate))
            }
          />
        </div>
        <div className='mb-3'>
          <DateSelector
            date={to}
            handleDateChange={
              (newDate) => dispatch(changeTimetrackingToDate(newDate))
            }
          />
        </div>
        <input
          className={`${styles['threshold-input']} mb-3`}
          type='range'
          onMouseUp={
            (e) => dispatch(changeTimetrackingThreshold(e.target.value))
          }
          min={minThreshold}
          max={maxThreshold}
        />
        <div>
          {viewTimeThreshold !== null ? (
            `Порог отсечения: ${convertTimeThreshold(viewTimeThreshold)}`
          ) : (
            'Порог отсечения не настроен'
          )}
        </div>
      </div>
      <DropQueryButton
        handleClick={() => dispatch(dropTimetrackingQuery())}
      />
    </div>
  )
}

export default TimetrackingQuerySelector
