import React from 'react'
import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import TaskProgress from './TaskProgress'

export default function TaskList ({ tasks, callbackHook }) {
  return (
    <Container
      className='mt-3'
    >
      <ListGroup>
        {tasks !== null ? (
          tasks.map(t => {
            return (
              <ListGroup.Item onClick={() => callbackHook(t.id)} key={t.id}>
                <Row>
                  <Col>
                    <Container>
                      {t.name}
                    </Container>
                  </Col>
                  <Col>
                    <TaskProgress taskId={t.id} />
                  </Col>
                </Row>
              </ListGroup.Item>
            )
          })) : null}
      </ListGroup>
    </Container>
  )
}
