export const filterNullValues = (obj) => {
  const res = Object.assign({}, obj)
  for (const k of Object.keys(res)) {
    if (res[k] === null) {
      delete res[k]
    }
  }
  return res
}

export const buildUrl = (url, jsonQuery) => {
  const queryParams = Object
    .keys(jsonQuery)
    .map(p => `${p}=${jsonQuery[p]}`)
  return url + '?' + queryParams.join('&')
}

export const toUnixTime = date => Math.floor(date / 1000)

export const fromUnixTime = timestamp => timestamp * 1000
