import BatchDissociatedBanner from './BatchDissociatedBanner'
import BatchNotFoundBanner from './BatchNotFoundBanner'
import NoMoreBatchesBanner from './NoMoreBatchesBanner'

const products = {
  BATCH_DISSOCIATED: BatchDissociatedBanner,
  BATCH_NOT_FOUND: BatchNotFoundBanner,
  NO_MORE_BATCHES: NoMoreBatchesBanner
}

const createBanner = (type) => {
  // check invalid type passsing
  if (!(type in products)) {
    return null
  }

  // create existing components
  return products[type]
}

export default {
  createBanner
}
