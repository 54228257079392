import React from 'react'
import { Alert } from 'react-bootstrap'

const NothingToShowBanner = () => {
  return (
    <Alert variant='info'>
      <Alert.Heading>Ничего на найдено</Alert.Heading>
      <div>Разметка за указанный период не найдена</div>
    </Alert>
  )
}

export default NothingToShowBanner
