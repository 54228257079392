import React from 'react'
import { useDispatch } from 'react-redux'
import { Container, Image, Row } from 'react-bootstrap'

import { API_PREFIX } from '../../../config'

import styles from './sequence-task.module.css'

export default function SequenceTaskBatch ({ batchImages }) {
  const dispatch = useDispatch()

  const images = batchImages.slice()

  for (let i = 0; i < images.length; i++) {
    const image = images[i]
    image.styleCls = image.markup !== null &&
      image.markup.marked ? styles['selected-image'] : styles.image
  }

  const handleMarkup = (imageId) => {
    const image = images.filter(i => i.id === imageId)[0]
    const prevMarkup = image.markup
    const newMarkup = !prevMarkup.marked

    const markup = {
      marked: newMarkup
    }
    dispatch({
      type: 'SET_MARKUP',
      imageId: imageId,
      markup: markup
    })
  }

  return (
    <Container className='task-container text-center'>
      <Container className={styles['batch-container']}>
        <Row className='justify-content-center'>
          {images.map((img) => {
            const containerClasses = [
              'col-2',
              'col-sm-2',
              'col-md-2',
              'p-2',
              styles['batch-image-container']
            ]
            const imgClasses = [
              styles['batch-image'],
              img.styleCls
            ]
            return (
              <div
                key={img.id}
                className={containerClasses.join(' ')}
              >
                <div>
                  <Image
                    className={imgClasses.join(' ')}
                    onClick={() => handleMarkup(img.id)}
                    fluid
                    src={
                      `${API_PREFIX}/images/${img.id}/image`
                    }
                  />
                </div>
              </div>
            )
          })}
        </Row>
      </Container>
    </Container>
  )
}
