import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { assignUser, dissUser } from '../../../../actions/adminPanel'
import ItemsManager from '../../../Utils/ItemsManager/component'
import { adminPanelSelector } from '../../selectors'
import { splitAssignedUsers } from '../utils'

const UserManager = ({ task }) => {
  const { users } = useSelector(adminPanelSelector)
  const dispatch = useDispatch()

  if (users === null) {
    return null
  }

  const {
    assignedItems: assignedUsers,
    dissociatedItems: dissociatedUsers
  } = splitAssignedUsers(users, task)

  const assignedProps = {
    topic: 'Добавленные пользователи',
    items: assignedUsers.filter(u => !u.is_admin),
    handleDoubleClick: (u) => dispatch(dissUser(u, task.id))
  }
  const dissociatedProps = {
    topic: 'Недобавленные пользователи',
    items: dissociatedUsers.filter(u => !u.is_admin),
    handleDoubleClick: (u) => dispatch(assignUser(u, task.id))
  }

  return (
    <ItemsManager
      itemKey='email'
      assignedProps={assignedProps}
      dissociatedProps={dissociatedProps}
    />
  )
}

export default UserManager
