import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { deleteMarkup } from '../../../../../actions/adminPanel'

const DeleteMarkupButton = ({ batchId }) => {
  const dispatch = useDispatch()

  return (
    <Button
      variant='danger'
      onClick={() => dispatch(deleteMarkup())}
    >
      <FontAwesomeIcon icon={faTrashAlt} size='md' />
    </Button>
  )
}

export default DeleteMarkupButton
