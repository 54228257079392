import React from 'react'
import { Form } from 'react-bootstrap'
import { splitAssignedTasks } from '../utils'

const TaskSelector = ({ data, handleIdChange }) => {
  const { users, tasks, userId, taskId } = data

  let assignedTasks = tasks
  if (userId !== null && users !== null) {
    const user = users.filter(
      u => u.id === userId
    )[0]
    assignedTasks = splitAssignedTasks(tasks, user).assignedItems
  }

  return (
    <Form.Control
      as='select'
      className='mb-3'
      onChange={handleIdChange}
    >
      <option value='' disabled selected={taskId === null}>Выберите задание</option>
      {assignedTasks !== null ? (
        assignedTasks.map(t => {
          return (
            <option
              selected={t.id === taskId}
              value={t.id}
              key={t.id}
            >
              {t.name}
            </option>
          )
        })
      ) : null}
    </Form.Control>
  )
}

export default TaskSelector
