import React from 'react'
import { Col, Row, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import NothingToShowBanner from './NothingToShowBanner'
import QueryHint from './QueryHint'
import SideBar from './SideBar/component'
import Viewer from './Viewer/component'

const TimetrackingPanel = () => {
  const data = useSelector(
    state => state.adminPanelReducer.timetrackingData
  )

  let view
  if (data !== null) {
    if (data.days_info.length === 0) {
      view = <NothingToShowBanner />
    } else {
      view = <Viewer data={data} />
    }
  } else {
    view = <QueryHint />
  }

  return (
    <Tab.Container>
      <Row className='mt-3 ml-1'>
        <Col className='col-2'>
          <SideBar />
        </Col>
        <Col className='col-10 text-center'>
          {view}
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default TimetrackingPanel
