import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  changeQueryDate,
  changeQueryTaskId,
  changeQueryUserId, dropQuery
} from '../../../../../../actions/adminPanel'
import { adminPanelSelector } from '../../../../selectors'
import DateSelector from '../../../common/DateSelector/component'
import PaginatedDateSelector from '../../../common/PaginatedDateSelector'
import TaskSelector from '../../../common/TaskSelector'
import UserSelector from '../../../common/UserSelector'
import { markupQuerySelector } from '../selectors'
import DropQueryButton from '../../../common/DropQueryButton'

const MarkupQuerySelector = () => {
  const { users, tasks } = useSelector(adminPanelSelector)
  const { taskId, userId, date } = useSelector(markupQuerySelector)
  const dispatch = useDispatch()

  const selectorData = { users, tasks, userId, taskId }

  return (
    <div className='text-center mb-3'>
      <UserSelector
        data={selectorData}
        handleIdChange={
          (e) => dispatch(changeQueryUserId(Number(e.target.value)))
        }
      />
      <TaskSelector
        data={selectorData}
        handleIdChange={
          (e) => dispatch(changeQueryTaskId(Number(e.target.value)))
        }
      />
      <PaginatedDateSelector
        date={date}
        handleDateChange={
          (newDate) => dispatch(changeQueryDate(newDate))
        }
      />
      <DropQueryButton
        handleClick={() => dispatch(dropQuery())}
      />
    </div>
  )
}

export default MarkupQuerySelector
