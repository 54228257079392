import React from 'react'
import { Container } from 'react-bootstrap'

import { API_PREFIX } from '../../../config'
import Img from '../../Utils/Img'

import styles from './style.module.css'

export default function OneImageBatch ({ batchImages }) {
  return (
    <Container className='text-center'>
      <Container className={styles['batch-container']}>
        <Img
          src={
            `${API_PREFIX}/images/${batchImages[0].id}/image`
          }
        />
      </Container>
    </Container>
  )
}
