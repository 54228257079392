import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { addUser } from '../../../../actions/adminPanel'

const initialState = {
  email: '',
  password: '',
  last_name: '',
  first_name: '',
  middle_name: ''
}

const placeholders = {
  email: 'Введите email',
  password: 'Введите пароль',
  last_name: 'Введите фамилию',
  first_name: 'Введите имя',
  middle_name: 'Введите отчество'
}

function UserCreateModal (props) {
  const [isCreating, setIsCreating] = useState(false)
  const [formData, setFormData] = useState(initialState)

  const dispatch = useDispatch()

  const handleCreate = async (e) => {
    e.preventDefault()
    setIsCreating(true)
    if (formData.email === '' || formData.password === '') {
      setIsCreating(false)
      props.createHook(false)
      return
    }

    dispatch(addUser(formData))
    setIsCreating(false)
    setFormData(initialState)
    props.createHook(false)
  }

  const updateFormField = (key, e) => {
    const newFormData = { ...formData }
    newFormData[key] = e.target.value
    setFormData(newFormData)
  }

  const handleHide = () => {
    setFormData(initialState)
    props.createHook(false)
  }

  return (
    <Modal show={props.show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Создание нового пользователя</Modal.Title>
      </Modal.Header>

      <Form className='mb-3'>
        <Modal.Body>
          {Object.entries(formData).map((key, value) => {
            key = key[0]
            return (
              <Form.Control
                className='mb-3'
                value={formData[key]}
                onChange={(e) => updateFormField(key, e)}
                placeholder={placeholders[key]}
                key={key}
              />
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            variant='success'
            disabled={isCreating}
            onClick={handleCreate}
          >Создать
          </Button>
        </Modal.Footer>

      </Form>
    </Modal>
  )
}

export default UserCreateModal
