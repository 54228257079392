import { handleActions } from 'redux-actions'
import stateMachine from '../components/TaskCore/taskCoreStateMachine'

const defaultState = {
  type: stateMachine.INIT,
  isFetching: false,
  isFirstLoad: true,
  markupType: null,
  batchId: null,
  batchImages: null,
  settings: null
}

export default handleActions({
  LOCK_TASKS_FETCH: (state, action) => {
    return {
      ...state,
      isFetching: true
    }
  },
  OPEN_TASK: (state, action) => {
    return {
      ...state,
      type: stateMachine.INIT,
      isFetching: false,
      isFirstLoad: true,
      markupType: null,
      batchId: null,
      batchImages: null,
      settings: null
    }
  },
  INIT_TASK: (state, action) => {
    return {
      ...state,
      markupType: action.markupType,
      settings: action.settings,
      isFetching: false,
      type: stateMachine.LOAD
    }
  },
  DROP_TASK: (state, action) => {
    return {
      type: stateMachine.DEFAULT,
      isFetching: false,
      isFirstLoad: true,
      markupType: null,
      batchId: null,
      batchImages: null,
      settings: null
    }
  },
  SET_MARKUP_CONTEXT: (state, action) => {
    return {
      ...state,
      batchId: action.batchId,
      batchImages: action.images,
      isFetching: false,
      isFirstLoad: false,
      type: stateMachine.MARKUP
    }
  },
  SET_MARKUP: (state, action) => {
    const images = state.batchImages.slice()
    const image = images
      .filter((img) => img.id === action.imageId)[0]
    image.markup = action.markup
    return {
      ...state,
      batchImages: images
    }
  },
  SAVE_MARKUP: (state, action) => {
    return {
      ...state,
      type: stateMachine.SAVE
    }
  },
  SKIP_BATCH: (state, action) => {
    return {
      ...state,
      type: stateMachine.SKIP
    }
  },
  LOAD_NEW_BATCH: (state, action) => {
    return {
      ...state,
      batchId: null,
      batchImages: null,
      isFetching: false,
      type: stateMachine.LOAD
    }
  },
  GO_BACK: (state, action) => {
    return {
      ...state,
      batchId: action.batchId,
      batchImages: null,
      isFetching: false,
      type: stateMachine.LOAD
    }
  },
  NO_MORE_BATCHES: (state, action) => {
    return {
      ...state,
      type: stateMachine.NO_MORE_BATCHES
    }
  },
  BATCH_NOT_FOUND: (state, action) => {
    return {
      ...state,
      type: stateMachine.BATCH_NOT_FOUND
    }
  },
  BATCH_DISSOCIATED: (state, action) => {
    return {
      ...state,
      type: stateMachine.BATCH_DISSOCIATED
    }
  },
  INIT_MARKUP: (state, action) => {
    return {
      ...state,
      batchImages: action.initImages
    }
  }
}, defaultState)
