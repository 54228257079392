export const splitAssignedUsers = (users, task) => {
  const assignedUsers = []
  const dissociatedUsers = []
  users.forEach(u => {
    if (u.tasks.filter(t => t.id === task.id).length === 0) {
      dissociatedUsers.push(u)
    } else {
      assignedUsers.push(u)
    }
  })
  return {
    assignedItems: assignedUsers,
    dissociatedItems: dissociatedUsers
  }
}

export const splitAssignedTasks = (tasks, user) => {
  const assignedTasks = []
  const dissociatedTasks = []
  tasks.forEach(t => {
    if (user.tasks.filter(ut => t.id === ut.id).length === 0) {
      dissociatedTasks.push(t)
    } else {
      assignedTasks.push(t)
    }
  })
  return {
    assignedItems: assignedTasks,
    dissociatedItems: dissociatedTasks
  }
}
